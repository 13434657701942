export enum DeviceType {
  DON = 'DON',
  NIT = 'NIT',
  VAG = 'Vag',
  FN = 'Falltal',
  /**
   * Manuel instrument
   */
  MI = 'MI',
  REF_TEST_WEIGHT = 'REF-Rymdvikt'
}