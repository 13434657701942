import styled from '@emotion/styled';

import { LmButton } from './lm2/LmButton';
import { useEffect, useState } from 'react';
import { Device, DeviceInput } from '../types';
import { FlexContainer, FlexItem } from './FlexLayout';
import { LmInput } from './lm2/LmInput';
import { LinearLoader } from './shared/LinearLoader';
import { Overlay } from './shared/Overlay';
import { CardColumn } from './shared/Card';
import { MenuItem, TextField } from '@mui/material';
import { DeviceType } from '../constants/deviceTypes';

interface Props {
  device?: Device;
  onSave: (inputValue: DeviceInput) => Promise<void>;
}

const Card = styled.div`
  position: relative;
  border: 2px solid silver;
  padding: 20px;
  margin-bottom: 4px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;

const CheckboxLabel = styled.label`
  width: 30%
`;

export const DeviceCard = ({ device, onSave }: Props) => {

  const createDeviceInput = (device?: Device): DeviceInput => {
    return {
      deviceId: device?.id ?? '',
      deviceType: device?.deviceType ?? '',
      deviceModel: device?.deviceModel ?? '',
      location: device?.location ?? '',
      alias: device?.alias ?? '',
      serialNumber: device?.serialNumber ?? '',
      eurofinsCode: device?.eurofinsCode ?? '',
      sendToM3: device?.sendToM3 ?? false,
      createTraceability: device?.createTraceability ?? false,
    }
  }
  const [formValues, setFormValues] = useState<DeviceInput>(createDeviceInput(device));
  const [loading, setLoading] = useState(false);

  const handleChange = (event: any) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = (event:React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setFormValues(createDeviceInput(device));
  }, [device]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    await onSave(formValues);
    setLoading(false);
  };

  const deviceTypes = Object.values(DeviceType);
  return (
    <Card>
      {loading ? (
        <Overlay>
          <LinearLoader />
        </Overlay>
      ) : null}
      <form
        onSubmit={handleSubmit}
      >
        <h3 style={{ textAlign: 'center' }}>Editera instrument</h3>
        <FlexContainer>
          <CardColumn width={50}>
            <FlexItem width={40}>
              <label htmlFor="id">Instrumentid</label>
              <LmInput
                type="text"
                id="id"
                name="deviceId"
                value={formValues.deviceId}
                onChange={handleChange}
              />
              <label htmlFor="deviceType">Instrumenttyp</label>
              <TextField
                type="text"
                id="deviceType"
                name="deviceType"
                fullWidth
                select
                value={formValues.deviceType}
                onChange={handleChange}
                hiddenLabel={true}
                style={{ width: '50%', display: 'block' }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#3aaa35',
                    }
                  }
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {deviceTypes?.map(d => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </TextField>
              <label htmlFor="deviceModel">Instrumentmodell</label>
              <LmInput
                type="text"
                id="deviceModel"
                name="deviceModel"
                value={formValues.deviceModel}
                onChange={handleChange}
              />
              <label htmlFor="ort">Ort</label>
              <LmInput
                type="text"
                id="ort"
                name="location"
                value={formValues.location}
                onChange={handleChange}
              />
            </FlexItem>
          </CardColumn>
          <CardColumn width={50}>
            <FlexItem width={50}>
              <label htmlFor="alias">Alias</label>
              <LmInput
                type="text"
                id="alias"
                name="alias"
                value={formValues.alias}
                onChange={handleChange}
              />
              <label htmlFor="serialNumber">Serienummer</label>
              <LmInput
                type="text"
                id="serialNumber"
                name="serialNumber"
                value={formValues.serialNumber}
                onChange={handleChange}
              />
              <label htmlFor="eurofinsCode">Inventarienummer</label>
              <LmInput
                type="text"
                id="eurofinsCode"
                name="eurofinsCode"
                value={formValues.eurofinsCode}
                onChange={handleChange}
              />
              <div>
                <CheckboxLabel htmlFor="sendToM3">Skicka till M3</CheckboxLabel>
                <LmInput
                  type="checkbox"
                  id="sendToM3"
                  name="sendToM3"
                  checked={formValues.sendToM3}
                  onChange={handleChangeCheckbox}
                />
              </div>
              <div>
                <CheckboxLabel htmlFor="createTraceability">Skapa spårbarhet</CheckboxLabel>
                <LmInput
                  type="checkbox"
                  id="createTraceability"
                  name="createTraceability"
                  checked={formValues.createTraceability}
                  onChange={handleChangeCheckbox}
                />
              </div>
            </FlexItem>
          </CardColumn>
        </FlexContainer>
        <FlexContainer justify="flex-end">
          <FlexItem>
            <LmButton>Spara</LmButton>
          </FlexItem>
        </FlexContainer>
      </form>
    </Card>
  );
};
